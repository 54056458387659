import gql from 'graphql-tag'

export const CREATE_TOPIC = (templateType) => gql`mutation CREATE_TOPIC ($paperType: String!, $paperId: Int!, $input: ${templateType}TopicInput!) {
  createTopic: create${templateType}Topic (paperType: $paperType, paperId: $paperId, input: $input) {
    id revision effectiveDate remark
  }
}`

export const UPDATE_TOPIC = (templateType) => gql`mutation UPDATE_TOPIC ($paperType: String!, $topicId: Int!, $input: ${templateType}TopicInput!) {
  updateTopic: update${templateType}Topic (paperType: $paperType, topicId: $topicId, input: $input) {
    id revision effectiveDate remark
  }
}`

export const DESTROY_TOPIC = (templateType) => gql`mutation DESTROY_TOPIC ($paperType: String!, $topicId: Int!) {
  destroyTopic: destroy${templateType}Topic (paperType: $paperType, topicId: $topicId) {id}
}`
